import axios from "@/axios";
import store from "@/store";
import moment from "moment";


export function getActiveOffer(targetOffers) {
    const allOffers = store.state.offers.offers;
    if (targetOffers && targetOffers.length > 0 && allOffers.length > 0) {
        let offers =
            targetOffers
                .map(id => allOffers.find(c => c.id === id))
                .filter(Boolean)
                // remove out of date offers
                .filter(c => {
                    return c.fromDate && c.toDate
                        ? moment().isBetween(c.fromDate, c.toDate, 'day', '[]')
                        : true
                })
                .map(c => ({
                    ...c,
                    // get remaining duration
                    remainingDuration:
                        c.fromDate && c.toDate
                            ? new Date(c.toDate) - new Date(c.fromDate)
                            : Infinity
                }));
        offers = offers.sort((a, b) => b.remainingDuration > a.remainingDuration > 0 ? -1 : 1);
        
        if (!offers.length) return null;

        const activeOffer = offers[offers.length - 1];
        return activeOffer;
    }
    return null;
}
export function getOfferPrice(targetOffers, originalPrice, isUpSelling) {
    const activeOffer = getActiveOffer(targetOffers);
    
    if (!activeOffer) return null;
    if (isUpSelling && activeOffer.fixedValue) return null;
    
    const offAmount =
        activeOffer.percentValue
            ? ((activeOffer.percentValue / 100) * originalPrice)
            : activeOffer.fixedValue || 0
    const finalPrice = originalPrice - offAmount;
    return finalPrice >= 0 ? finalPrice : 1e-5;
}

export function getRandomIndexesWithoutRepetition(array, numberOfIndexes) {
    if (numberOfIndexes > array.length) {
        return array.map((c, i) => i);
    }

    const indexes = [];
    while (indexes.length < numberOfIndexes) {
        const randomIndex = Math.floor(Math.random() * array.length);
        if (!indexes.includes(randomIndex)) {
            indexes.push(randomIndex);
        }
    }
    return indexes;
}

export function checkIfFromKuwait() {
    /* return new Promise((resolve) => {
        resolve(false);
    }) */
    return axios('/user-location').then(({ data }) => {
        if (data.countryName && data.countryName.toLowerCase().includes('kuwait')) {
            return true;
        } else {
            return false;
        }
    }).catch((error) => {
        throw error;
    });
}

export const validate = {
    email: val => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(val)
    }
}