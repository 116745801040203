export const countryCurrency = [
    { id: 0, country: 'السعودية', currency: 'SAR' },
    { id: 1, country: 'البحرين', currency: 'BHD' },
    { id: 2, country: 'الإمارات', currency: 'AED' },
    { id: 3, country: 'قطر', currency: 'QAR' },
    { id: 4, country: 'عُمان', currency: 'OMR' },
    { id: 5, country: 'الأردن', currency: 'JOD' },
    { id: 6, country: 'مصر', currency: 'EGP' },
]

export const sortingTypes = [
    { id: 0, name: 'الأحدث إلى الأقدم' },
    { id: 1, name: 'الأعلى تقييماً' },
    { id: 2, name: 'الأدنى تقييماً' },
]