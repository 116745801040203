import { countryCurrency } from "./enums";

let activeCurrency = 'د.ك';
let activeCurrencyCode = 'KWD';

const changeCurrency = (currency) => {
    activeCurrencyCode = currency;
    localStorage.setItem('currency', JSON.stringify(currency));
    if (countryCurrency.map(c => c.currency).includes(currency)) {
        activeCurrency = currency;
        activeCurrencyCode = currency;
    } else {
        activeCurrency = 'د.ك';
        activeCurrencyCode = 'KWD';
        // activeCurrency = '$';
        // activeCurrencyCode = 'USD';
    }
    document.documentElement.style.setProperty('--active-currency', `"${activeCurrency}"`);
}
changeCurrency(JSON.parse(localStorage.getItem('currency')) || 'KWD');

export { activeCurrency, activeCurrencyCode, changeCurrency }