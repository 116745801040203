import { activeCurrencyCode } from "@/helpers/currency";

/**
 * @param {AxiosStatic} axios
 * @param {Store} store
 * @param {VueRouter} router
 */

export default (axios, store, router) => {
	axios.interceptors.request.use(
		(config) => {
			if (store.state.auth.user) {
				config.headers.Authorization = 'Bearer ' + store.state.auth.user.access_token;
			} else if (config.url.includes('cart')) {
				const cartId = localStorage.getItem('cart-id');
				if (cartId) {
					config.params = { ...config.params, cart_id: cartId };
				}
			}
			
			if (
				config.url.includes('product') ||
				config.url.includes('pay') ||
				config.url.includes('cart')
			) {
				config.params = {
					...config.params,
					convertCurrency: activeCurrencyCode
				};
			}

			return config;
		},
		(err) => {
			return Promise.reject(err);
		},
	);
	axios.interceptors.response.use(
		function (response) {
			return response;
		},
		async function (error) {
			// todo return when 401 and cart
			if (
				(error.response?.status === 404 || error.response?.status === 401) &&
				error.config.url !== '/authentication' &&
				error.config.url !== '/users/get-point'
			) {
				const user = localStorage.getItem('auth2');
				if (!error.config._retry && user && JSON.parse(user)) {
					error.config._retry = true;
					try {
						// todo:auth change dispatch to login with refresh token
						// await store.dispatch('auth/login', JSON.parse(user));
						store.commit('auth/setUserFromLS');
						return axios(error.config);
					} catch (_e) {
						store.dispatch('auth/logout');
						return Promise.reject(_e);
					}
				} else {
					store.dispatch('auth/logout');
					return Promise.reject(error);
				}
			}
			// other errors
			return Promise.reject(error);
		},
	);
};
